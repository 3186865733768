import React, { useState } from 'react'
import Button from '../../components/button/Button'
import Input from '../../components/input/Input'
import { generatePlaylist } from '../../services/PlaylistService'
import { useNavigate } from 'react-router-dom'

import "./home.css"
import Footer from '../../components/footer/Footer'

export default function Home() {
    const [isLoading, setLoading] = useState(false)
    const [phrase, setPhrase] = useState(null)
    const navigate = useNavigate()

    function handlePhraseChange(event) {
        setPhrase(event.target.value.trim())
    }

    function handleSuccess(playlist) {
        navigate("/playlist", {
            state: { playlist }
        })
    }

    function handleError(errorResponse) {
        if (errorResponse.code === 'ECONNABORTED') {
            alert("Could not generate playlist (timeout). Try again later...")
            return
        }
        if (errorResponse.code === 'ERR_CONNECTION_REFUSED') {
            alert("Server is busy. Try again later...")
            return
        }

        const { data } = errorResponse.response
        if (data.error) {
            console.log(data.error)
            const { message, words } = data.error
            if (words?.length === 0) {
                alert(message)
                return
            }
            alert(`Words not found: ${words.map(obj => `'${obj.word}'`).join(", ")}`)
        } else {
            alert("Could not generate playlist. Try again later...")
        }
    }

    async function handleButtonGeneratePlaylist() {
        if (!phrase || phrase === "") {
            alert("You have to input at least one word")
            return
        }

        setLoading(true)
        try {
            const response = await generatePlaylist(phrase)
            if (response.status === 200)
                handleSuccess(response.data.data)
            else
                handleError(response.data)
        } catch (e) {
            handleError(e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <div className='home-container'>
                <div className='text'>
                    <h1 className='title'>Phrase2Playlist</h1>
                    <p className='subtitle'>Turn any phrase into a Spotify playlist</p>
                </div>
                <div className='input-container'>
                    <Input
                        placeholder="Insert your phrase here..."
                        onChange={handlePhraseChange}
                    />
                    <Button
                        blocked={isLoading}
                        onClick={handleButtonGeneratePlaylist}

                    >{isLoading ? "Generating..." : "Generate"}
                    </Button>
                </div>
                <Footer />

            </div>
        </>
    )
}
