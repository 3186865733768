import React from 'react'
import PlayButton from '../playbutton/PlayButton'
import "./track.css"

export default function Track({ onPlayButtonClick, shouldAudioPlay, id, track, preview, album, artists }) {
    let artistsNames = formatArtistsNames()

    const albumType = album.type.charAt(0).toUpperCase() + album.type.slice(1);

    return (
        <div key={id} className='track-row'>
            <a target="_blank" rel="noreferrer" href={`https://open.spotify.com/album/${album.id}`}>
                <img src={album.image} className="album-image" alt={`${album.name} ${album.type} cover`} />
            </a>
            <div className="text-info">
                <div className='track-name'>
                    <PlayButton onClick={onPlayButtonClick} shouldAudioPlay={shouldAudioPlay} mp3Url={preview} />
                    <p>
                        <a target="_blank" rel="noreferrer" href={`https://open.spotify.com/track/${id}`}>
                            {track}
                        </a>
                    </p>
                </div>
                <p className='artist-name'>By {artistsNames}</p>
                <p className='album-name'>
                    {albumType}{" "}
                    <a target="_blank" rel="noreferrer" href={`https://open.spotify.com/album/${album.id}`}>
                        {album.name}
                    </a>
                </p>
            </div>
        </div>
    )

    function formatArtistsNames() {
        const artistsNames = artists.map(artist => <a key={artist.id} rel="noreferrer" target="_blank" href={`https://open.spotify.com/artist/${artist.id}`}>{artist.name}</a>)
        let artistsText = artistsNames
        if (artistsNames.length > 1) {
            artistsText = []
            const artistsLength = artistsNames.length
            for (let i = 0; i <= artistsLength - 2; i++) {
                artistsText.push(artistsNames[i])

                if (i < artistsLength - 2)
                    artistsText.push(<span key={`span-comma-${id}-${i}`}>, </span>)
            }
            artistsText.push(<span key={`span-comma-${id}-and`}> & </span>)
            artistsText.push(artistsNames[artistsLength - 1])
        }
        return artistsText
    }
}
