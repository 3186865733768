import React from 'react'
import { Navigate } from 'react-router-dom';
import qs from "qs"

export default function Loading() {
    const hash = window.location.hash.substring(1)
    const params = qs.parse(hash);

    localStorage.setItem("user_token", JSON.stringify({ ...params, created_at: new Date().getTime() }));
    const pid = localStorage.getItem("pid")

    return (
        <Navigate to={`/playlist?pid=${pid}&spotify_callback=1`} replace={true} />
    )
}
