import React from 'react'
import Home from './screens/home/Home'
import Playlist from './screens/playlist/Playlist'
import SpotifyCallback from './screens/spotify_callback/SpotifyCallback'
import { createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/playlist",
        element: <Playlist />,
        errorElement: <Home />
    },
    {
        path: "/spotify_callback",
        element: <SpotifyCallback />,
        errorElement: <Home />
    }
]);

export default router