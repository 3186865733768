import axios from "axios"

export const spotifyBaseUrl = "https://accounts.spotify.com"
export const webUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    ? "http://localhost:3000"
    : "https://phrase2playlist.app"

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:8080",
    timeout: 10000,
    headers: {}
});