import React, { useEffect, useRef, useState } from 'react'
import { BsFillPlayFill, BsFillPauseFill } from 'react-icons/bs';
import "./play-button.css"

export default function PlayButton({ onClick, mp3Url, shouldAudioPlay }) {
    const audio = useRef(null)
    const [isPlaying, setPlaying] = useState(false)

    useEffect(() => {
        if (!shouldAudioPlay) {
            audio.current.pause()
            setPlaying(false)
        }
    }, [shouldAudioPlay])

    function togglePlay() {
        if (isPlaying)
            pause()
        else
            play()
    }

    function play() {
        audio.current.play()
        setPlaying(true)
    }

    function pause() {
        audio.current.pause()
        setPlaying(false)
    }

    return (
        <>
            <audio src={mp3Url} ref={audio} onEnded={() => setPlaying(false)} />
            <button className='play-button' onClick={() => { onClick(); togglePlay(); }}>
                {isPlaying ? <span id="pause"><BsFillPauseFill /></span> : <span id="play"><BsFillPlayFill /></span>}
            </button>
        </>
    )
}
