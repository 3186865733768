import React from 'react'
import "./button.css"

export default function Button({ id, style, blocked, children, onClick }) {
    return (
        <>
            <button style={style} id={id} disabled={blocked} onClick={onClick} className='button'>{children}</button>
        </>
    )
}
