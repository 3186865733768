import { api } from "./config";

export const generatePlaylist = async (phrase) => {
    console.log("Fetching API...")
    const response = await api({
        method: "post",
        url: "/playlists",
        data: {
            phrase
        },
    })

    if (response.status !== 200)
        console.error(response.data)

    return response
}

export const savePlaylist = async (userToken, playlistName, uris) => {
    console.log("Fetching API...")
    const response = await api({
        method: "post",
        url: "/me/playlists",
        headers: {
            Authorization: "Bearer " + userToken
        },
        data: {
            playlist_name: playlistName,
            uris
        },
    })

    if (response.status !== 201)
        console.error(response.data)

    return response
}

export const retrievePlaylist = async (id) => {
    console.log("Fetching API...")
    const response = await api({
        method: "get",
        url: "/playlists/" + id,
    })

    if (response.status !== 200)
        console.error(response.data)

    return response
}