import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import "./input.css"

export default function Input({ placeholder, onChange }) {
    return (
        <>
            <TextareaAutosize
                minRows={1}
                onChange={onChange}
                placeholder={placeholder}
                className='input' />
        </>
    )
}
