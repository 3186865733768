import React, { useEffect, useState } from 'react'
import Track from '../../components/track/Track'
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Button from '../../components/button/Button';
import { shareOnMobile } from "react-mobile-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { retrievePlaylist, savePlaylist } from '../../services/PlaylistService';
import { IoMdArrowBack } from "react-icons/io"
import { BsSpotify } from "react-icons/bs"
import Loading from '../loading/Loading';
import Footer from '../../components/footer/Footer';
import { webUrl } from "../../services/config"
import { buildAuthUrl, getToken, getUserId, isLoggedIn } from "../../services/AuthService"
import "./playlist.css"

export default function Playlist() {
    const navigate = useNavigate()
    const [addToSpotifyText, setAddToSpotifyText] = useState("Add to Spotify")
    const [addToSpotifyButtonBlocked, setAddToSpotifyButtonBlocked] = useState(false)
    const [loading, setLoading] = useState(true)
    const [query] = useSearchParams()
    const playlistId = query.get('pid')
    const spotifyCallback = query.get('spotify_callback')
    const hasPlaylistId = playlistId && playlistId !== ""
    const { state } = useLocation();
    const [currentPlayingAudio, setCurrentPlayingAudio] = useState(null)
    const [data, setData] = useState(null)

    useEffect(() => {
        async function fetchPlaylist() {
            setLoading(true)
            const playlist = await retrievePlaylist(playlistId)
            setData(playlist.data.data)
            setLoading(false)
        }

        if (hasPlaylistId) {
            fetchPlaylist()
        } else if (state) {
            setData(state.playlist)
            setLoading(false)
        }
    }, [hasPlaylistId])
    useEffect(() => {
        if (data && spotifyCallback === "1" && !addToSpotifyButtonBlocked) {
            console.log("Adding playlist from callback")
            addPlaylistToSpotify(data["playlist_name"], data.tracks)
        }
    }, [data])

    if (!state && !hasPlaylistId) {
        return <Navigate to="/" replace={true} />
    }

    function getPlaylistId() {
        return tracks.map(track => track.id).join('.')
    }

    function getShareableLink() {
        return `${webUrl}/playlist?pid=${getPlaylistId(tracks)}`
    }

    function renderShareButton() {
        return <>
            {isMobile ? (
                <Button
                    id="share-button"
                    onClick={() => shareOnMobile({
                        title: `Checkout my cool playlist: "${playlistName}"!`,
                        url: getShareableLink(),
                    })}>Share</Button>
            ) : (
                <CopyToClipboard text={`Checkout my cool playlist: "${playlistName}"!\n\n${getShareableLink()}`}>
                    <Button id="share-button" onClick={() => alert("Shareable link copied to your clipboard!")}>Share link to playlist</Button>
                </CopyToClipboard>
            )}
        </>
    }

    function login() {
        localStorage.setItem("pid", getPlaylistId());

        const authUrl = buildAuthUrl(`${webUrl}/spotify_callback`)
        window.location = authUrl
    }

    async function addPlaylistToSpotify(playlistName, tracks) {
        if (!isLoggedIn()) {
            return login()
        }

        setAddToSpotifyText("Adding to Spotify...")
        const userToken = getToken()
        const uris = tracks.map(track => track.uri)
        setAddToSpotifyButtonBlocked(true)
        try {
            await savePlaylist(
                userToken,
                playlistName,
                uris
            )
            setAddToSpotifyText("Added to Spotify :)")
        } catch (e) {
            console.error(e)
            setAddToSpotifyText("Add to Spotify")
            setAddToSpotifyButtonBlocked(false)
        }
    }

    if (loading) {
        return <Loading />
    }

    const playlistName = data["playlist_name"]
    const tracks = data.tracks

    return (
        <>
            <div className='playlist-container'>
                <div className='playlist-header'>
                    <span
                        onClick={() => navigate("/")}
                        className='back-button'>
                        <IoMdArrowBack />
                    </span>
                    <h1 className='playlist-name'>
                        {playlistName}
                    </h1>
                </div>
                {tracks.map(track => {
                    const previewAudio = track["preview_url"]
                    return <Track
                        onPlayButtonClick={() => setCurrentPlayingAudio(previewAudio)}
                        shouldAudioPlay={currentPlayingAudio === previewAudio}
                        key={track.id}
                        id={track.id}
                        track={track.name}
                        album={{
                            id: track.album.id,
                            name: track.album.name,
                            type: track.album.type,
                            image: track.album.images[0].url
                        }}
                        artists={track.artists}
                        preview={previewAudio}
                    />
                }
                )}
                <Button blocked={addToSpotifyButtonBlocked} onClick={() => addPlaylistToSpotify(playlistName, tracks)}>
                    <span id="spotify-icon"><BsSpotify /></span> {addToSpotifyText}
                </Button>
                {renderShareButton()}
                <Footer />

            </div>
        </>
    )
}
