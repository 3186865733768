import React from 'react'
import "./footer.css"

export default function Footer() {
    return (
        <>
            <footer>
                <p>Powered by <a target="_blank" href="https://github.com/margato" rel="noreferrer">Margato</a> & <a href="https://www.spotify.com/" target="_blank" rel="noreferrer">Spotify</a></p>
            </footer>
        </>
    )
}
