import { spotifyApi, spotifyBaseUrl } from "./config";
import { v4 as uuid } from 'uuid';

export const buildAuthUrl = (redirectUri) => {
    const scope = "playlist-modify-public"
    const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID

    let url = spotifyBaseUrl + "/authorize"

    url += '?response_type=token';
    url += '&client_id=' + encodeURIComponent(clientId);
    url += '&scope=' + encodeURIComponent(scope);
    url += '&redirect_uri=' + encodeURIComponent(redirectUri);
    url += '&state=' + encodeURIComponent(uuid());

    return url
}


export const getToken = () => {
    const userTokenString = localStorage.getItem("user_token")
    if (!userTokenString) {
        return null
    }

    const userToken = JSON.parse(userTokenString)
    return userToken["access_token"]
}

export const isLoggedIn = () => {
    const userTokenString = localStorage.getItem("user_token")
    if (!userTokenString) {
        return false
    }

    const userToken = JSON.parse(userTokenString)
    const createdAt = userToken["created_at"]
    const expiresIn = parseInt(userToken["expires_in"]) * 1000
    const now = new Date().getTime()

    return createdAt + expiresIn > now
}